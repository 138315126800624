import throttle from 'lodash.throttle'
import MenuWithDropdowns from './components/menuWithDropdowns'
import HeaderSearch from './components/headerSearch'
import { minDesktop } from '../../helpers/resize'

class MenuDropdown {
  constructor(el) {
    this.burgerMenuInitialized = false
    this.searchInitialized = false
    this.isScrolled = false
    this.menu = new MenuWithDropdowns(el)
    this.search = new HeaderSearch(el, {
      onSearchOpenCallback: () => this.menu.closeOpenSubmenus(),
    })

    /* Events */
    window.addEventListener('scroll', throttle(this.onScroll.bind(this), 30))
    minDesktop.addEventListener('change', this.onResize.bind(this))

    /* Initialize */
    this.initMenu()
    this.search.initSearch()
    this.menu.initSubmenus()

    if (minDesktop.matches) {
      this.menu.submenus.forEach((submenu) => {
        submenu.style.height = 'auto'
      })
      this.menu.menuContent.hidden = false
      this.menu.isHidden = false
      this.menu.isDesktop = true
    } else {
      this.menu.closeOpenSubmenus()
      this.menu.menuContent.hidden = true
      this.menu.isHidden = true
      this.menu.isDesktop = false
    }
  }

  onResize() {
    this.initMenu()
    this.search.initSearch()
  }

  initMenu() {
    if (minDesktop.matches && this.burgerMenuInitialized) {
      this.menu.destroy()
      this.burgerMenuInitialized = false
    } else if (!minDesktop.matches && !this.burgerMenuInitialized) {
      this.menu.init()
      this.burgerMenuInitialized = true
    }
  }

  onScroll() {
    if (window.scrollY > 200 && !this.isScrolled) {
      document.body.classList.add('is-scrolled')
      this.isScrolled = true
    } else if (window.scrollY <= 200 && this.isScrolled) {
      document.body.classList.remove('is-scrolled')
      this.isScrolled = false
    }
  }
}

export default MenuDropdown
