import { trapFocus } from '../../../helpers/trapFocus'
import bodyScrollLock from '../../../helpers/bodyScrollLock'

class HeaderSearch {
  constructor(el, { ...args }) {
    this.el = el
    this.onSearchOpenCallback = args.onSearchOpenCallback
    this.onSearchCloseCallback = args.onSearchCloseCallback
    this.searchButton = el.querySelector('[data-search-button]')
    this.searchCloseButton = el.querySelector('[data-close]')
    this.searchContent = el.querySelector('[data-search-content]')
    this.hasSearch = false
    this.isSearchOpen = false

    /* Events */
    this.onSearchClick = this.onSearchClick.bind(this)
    this.searchCloseHandleClick = this.closeSearch.bind(this)
    this.trapFocus = this.trapFocusInSearch.bind(this)
  }

  getSearchContentLinks() {
    return [
      this.searchCloseButton,
      ...this.searchContent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input'
      ),
    ].filter((el) => {
      const hiddenWithTabindex = el.getAttribute('tabindex') == '-1'
      return (
        getComputedStyle(el).getPropertyValue('display') !== 'none' &&
        !hiddenWithTabindex
      )
    })
  }

  trapFocusInSearch(e) {
    const escIsPressed = e.keyCode === 27 || e.key === 'Esc'
    const searchContentLinks = this.getSearchContentLinks()

    trapFocus(e, null, searchContentLinks)

    if (escIsPressed) {
      this.closeSearch()
      this.searchButton.focus()
    }
  }

  preventFocusOnLinks(bool) {
    const linksWithinSearchContent = [
      ...this.searchContent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input'
      ),
    ]

    linksWithinSearchContent.forEach((el) => {
      if (bool === true) {
        return el.setAttribute('tabindex', '-1')
      } else {
        return el.removeAttribute('tabindex')
      }
    })
  }

  openSearch() {
    this.searchContent.hidden = false
    this.preventFocusOnLinks(false)

    setTimeout(() => {
      document.body.classList.add('is-search-open')
      bodyScrollLock(true)
      this.searchButton.querySelector('[data-text]').innerText = 'Close'
      this.el.addEventListener('keydown', this.trapFocus)
      this.isSearchOpen = true
    }, 100)
  }

  closeSearch() {
    document.body.classList.remove('is-search-open')
    this.preventFocusOnLinks(true)
    bodyScrollLock(false)
    this.searchButton.querySelector('[data-text]').innerText = 'Search'
    this.el.removeEventListener('keydown', this.trapFocus)
    this.isSearchOpen = false

    setTimeout(() => {
      this.searchContent.hidden = true
    }, 300)
  }

  onSearchClick() {
    if (!this.isSearchOpen) {
      this.openSearch()

      if (
        this.onSearchOpenCallback &&
        typeof this.onSearchOpenCallback === 'function'
      ) {
        this.onSearchOpenCallback()
      }
    } else {
      this.closeSearch()
      if (
        this.onSearchCloseCallback &&
        typeof this.onSearchCloseCallback === 'function'
      ) {
        this.onSearchCloseCallback()
      }
    }
  }

  initSearch() {
    document.body.classList.add('has-search')
    this.preventFocusOnLinks(true)
    this.searchContent.hidden = true
    this.searchButton.addEventListener('click', this.onSearchClick)
    this.searchCloseButton.addEventListener(
      'click',
      this.searchCloseHandleClick
    )
    window.addEventListener('resize', this.throttledResize)
    this.hasSearch = true
  }

  destroySearch() {
    document.body.classList.remove('has-search')
    this.preventFocusOnLinks(false)
    bodyScrollLock(false)
    this.searchContent.removeAttribute('style')
    this.searchButton.removeEventListener('click', this.onSearchClick)
    this.searchCloseButton.removeEventListener(
      'click',
      this.searchCloseHandleClick
    )
    window.removeEventListener('resize', this.throttledResize)
    this.hasSearch = false
  }
}

export default HeaderSearch
