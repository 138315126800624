import throttle from 'lodash.throttle'
import resize from '../helpers/resize'

class Accordion {
  constructor(el) {
    this.el = el
    this.sections = [...el.querySelectorAll('[data-accordion-section]')]
    this.buttons = [...el.querySelectorAll('[data-btn]')]
    this.contentSections = [...el.querySelectorAll('[data-content]')]
    this.boundClickHandle = this.handleClick.bind(this)
    this.boundHandleResize = throttle(this.handleResize.bind(this), 50)
  }

  init() {
    this.contentSections.forEach((el) => this.setContentSection(el))
    this.el.addEventListener('click', this.boundClickHandle)
    window.addEventListener('resize', this.boundHandleResize)
  }

  destroy() {
    this.buttons.forEach((button) => {
      button.setAttribute('aria-expanded', false)
      button.classList.remove('is-expanded')
    })

    this.contentSections.forEach((el) => {
      el.removeAttribute('style')
      el.setAttribute('aria-hidden', false)

      this.linksInContent(el).forEach((el) => {
        el.removeAttribute('tabindex')
      })
    })

    this.el.removeEventListener('click', this.boundClickHandle)
    window.removeEventListener('resize', this.boundHandleResize)
  }

  toggleActiveClass(button) {
    if (button.classList.contains('is-expanded')) {
      button.classList.remove('is-expanded')
    } else {
      button.classList.add('is-expanded')
    }
  }

  handleClick(e) {
    if (!e.target.dataset.btn) return

    e.preventDefault()
    const button = e.target
    const i = parseInt(button.dataset.btn)
    const content = this.contentSections[i]
    let isExpanded = button.getAttribute('aria-expanded') === 'true' || false

    this.toggleActiveClass(button)
    button.setAttribute('aria-expanded', !isExpanded)
    content.setAttribute('aria-hidden', isExpanded)

    if (!isExpanded) {
      content.style.height = `${content.children[0].offsetHeight}px`
      this.enableContentLinks(content)
    } else {
      content.style.height = 0
      this.disableContentLinks(content)
    }

    isExpanded = !isExpanded
  }

  linksInContent(el) {
    return [
      ...el.querySelectorAll('a[href]:not([disabled]), button:not([disabled]'),
    ]
  }

  disableContentLinks(el) {
    this.linksInContent(el).forEach((el) => {
      el.setAttribute('tabindex', -1)
    })
  }

  enableContentLinks(el) {
    this.linksInContent(el).forEach((el) => {
      el.setAttribute('tabindex', 0)
    })
  }

  setContentSection(el) {
    el.setAttribute('aria-hidden', true)
    el.style.overflowY = 'hidden'
    el.style.height = 0
    this.disableContentLinks(el)
  }

  handleResize() {
    this.contentSections.forEach((el) => {
      if (el.getAttribute('aria-hidden') == 'false') {
        el.style.height = `${el.children[0].offsetHeight}px`
      }
    })
  }
}

export const render = (el) => {
  const accordion = new Accordion(el)
  accordion.init()
}
export default Accordion
