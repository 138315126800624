import throttle from 'lodash.throttle'

const delay = 1000 * 60 * 60 * 24 * 14 // Two weeks

class Popup {
  constructor(el) {
    this.el = el
    this.closeBtn = el.querySelector('[data-close-btn]')

    /* Events */
    this.boundOnScroll = throttle(this.handleScroll.bind(this), 200)

    this.init()
  }

  get cookiesAccepted() {
    return localStorage.getItem('cookiesAccepted')
  }

  get lastShown() {
    return parseInt(localStorage.getItem('popupLastShown'))
  }

  get shouldShowPopup() {
    return this.cookiesAccepted == 'true' && window.scrollY > 500
  }

  init() {
    const currentTimestamp = Date.now()
    const lastShown = this.lastShown || 0

    if (currentTimestamp > lastShown + delay) {
      window.addEventListener('scroll', this.boundOnScroll)
    }

    this.closeBtn.addEventListener('click', this.closePopup.bind(this))
  }

  handleScroll() {
    const currentTimestamp = Date.now()

    if (this.shouldShowPopup) {
      document.body.classList.add('is-popup-shown')
      localStorage.setItem('popupLastShown', currentTimestamp)

      setTimeout(() => {
        this.el.style.opacity = 1
      }, 300)
    }
  }

  closePopup() {
    const currentTimestamp = Date.now()
    this.el.style.opacity = 0
    window.removeEventListener('scroll', this.boundOnScroll)
    localStorage.setItem('popupLastShown', currentTimestamp)

    setTimeout(() => {
      document.body.classList.remove('is-popup-shown')
    }, 300)
  }
}

export default Popup
