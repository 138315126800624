import CookiesEuBanner from 'cookies-eu-banner'

const loadGtmScript = (gtm) => `
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer','${gtm}');`

const loadGaScript = (ga) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', ${ga}, {'anonymize_ip': true});

  var s = document.createElement('script');
  s.type = "text/javascript"
  s.async = "true";
  s.src = 'https://www.googletagmanager.com/gtag/js?id=${ga}';
  var x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);`

const createScript = (scriptContent, location) => {
  let newScript = document.createElement('script')
  const inlineScript = document.createTextNode(scriptContent)

  newScript.appendChild(inlineScript)
  location.appendChild(newScript)
}

class CookieBanner {
  constructor(el) {
    this.cookieBanner = new CookiesEuBanner(function () {
      localStorage.setItem('cookiesAccepted', true)
      /* Add the cookie scripts here */

      // Load GTM or GA if the dataset is populated
      if (el.dataset.gtmId) {
        const gtmScript = loadGtmScript(el.dataset.gtmId)
        createScript(gtmScript, document.head)
      } else if (el.dataset.gaId) {
        const gaScript = loadGaScript(el.dataset.gaId)
        createScript(gaScript, document.head)
      }
    }, true)
  }
}

export default CookieBanner
