class InstagramShare {
  constructor(el) {
    this.el = el
    this.data = {
      title: el.dataset.title,
      url: el.dataset.url,
    }

    el.addEventListener('click', this.onClick.bind(this))
  }

  onClick(e) {
    if (!navigator.share) return

    e.preventDefault()
    navigator
      .share(this.data)
      .then(() => console.log('Share was successful.'))
      .catch((error) => console.log('Sharing failed', error))
  }
}

export default InstagramShare
