import throttle from 'lodash.throttle'
import bodyScrollLock from '../../../helpers/bodyScrollLock'
import { trapFocus } from '../../../helpers/trapFocus'

class BurgerMenu {
  constructor(el, args = {}) {
    this.el = el
    this.onOpenCallback = args.onOpenCallback
    this.onCloseCallback = args.onCloseCallback
    this.menuButton = el.querySelector('[data-menu-button]')
    this.menuContent = el.querySelector('[data-menu-content]')
    this.isHidden = true

    /* Events */
    this.initToggleMenu = this.toggleMenu.bind(this)
    this.trapFocus = this.trapFocusInMenu.bind(this)
    this.onResize = this.setInitialStyles.bind(this)
    this.throttledResize = throttle(this.setInitialStyles, 600)
  }

  get headerHeight() {
    return this.el.clientHeight
  }

  setInitialStyles() {
    if (!this.menuContent) return

    this.menuContent.style.height = `${
      window.innerHeight - this.headerHeight
    }px`
    this.menuContent.style.top = `${this.headerHeight}px`
  }

  removeInitialStyles() {
    this.menuContent.removeAttribute('style')
  }

  trapFocusInMenu(e) {
    const escIsPressed = e.keyCode === 27 || e.key === 'Esc'

    trapFocus(e, this.el)

    if (escIsPressed) {
      this.close()
      this.menuButton.focus()
    }
  }

  allowMenuLinksFocus() {
    const menuLinks = [...this.menuContent.querySelectorAll('a')]
    menuLinks.forEach((el) => {
      el.setAttribute('tabindex', '0')
    })
  }

  preventMenuLinksFocus() {
    const menuLinks = [...this.menuContent.querySelectorAll('a')]
    menuLinks.forEach((el) => {
      el.setAttribute('tabindex', '-1')
    })
  }

  open() {
    this.menuContent.hidden = false
    this.setInitialStyles()
    this.allowMenuLinksFocus()

    if (this.onOpenCallback && typeof this.onOpenCallback === 'function') {
      this.onOpenCallback()
    }

    setTimeout(() => {
      document.body.classList.add('is-menu-open')
      bodyScrollLock(true)
      this.menuButton.querySelector('[data-text]').innerText = 'Close'
      this.el.addEventListener('keydown', this.trapFocus)
      this.menuButton.setAttribute('aria-expanded', 'true')
      this.isHidden = false
    }, 100)
  }

  close() {
    document.body.classList.remove('is-menu-open')
    bodyScrollLock(false)
    this.menuButton.querySelector('[data-text]').innerText = 'Menu'
    this.menuContent.querySelector('a').setAttribute('tabindex', -1)
    this.el.removeEventListener('keydown', this.trapFocus)
    this.menuButton.setAttribute('aria-expanded', 'false')
    this.preventMenuLinksFocus()
    this.isHidden = true

    if (this.onCloseCallback && typeof this.onCloseCallback === 'function') {
      this.onCloseCallback()
    }

    setTimeout(() => {
      this.menuContent.hidden = true
    }, 300)
  }

  handleClick() {
    if (this.isHidden) {
      this.open()
    } else {
      this.close()
    }
  }

  toggleMenu(e) {
    this.menuButton.setAttribute('aria-expanded', this.isHidden)

    if (this.isHidden && e.target === this.menuButton) {
      this.open()
    } else {
      this.close()
    }
  }

  init() {
    this.menuButton.addEventListener('click', this.initToggleMenu)
    this.menuContent.hidden = true
    this.close()
    this.el.classList.add('has-burger-menu')
    window.addEventListener('resize', this.throttledResize)
  }

  destroy() {
    this.menuButton.removeEventListener('click', this.initToggleMenu)
    window.removeEventListener('resize', this.throttledResize)
    this.el.classList.remove('has-burger-menu')
    this.removeInitialStyles()
    this.menuContent.hidden = false
    this.isHidden = false
  }
}

export default BurgerMenu
