import BurgerMenu from './burgerMenu'
import resize, { minDesktop } from '../../../helpers/resize'

class MenuWithDropdowns extends BurgerMenu {
  constructor(el) {
    super(el)
    this.buttons = [...el.querySelectorAll('[data-submenu-button]')]
    this.submenus = [...el.querySelectorAll('[data-submenu]')]
    this.isDesktop = minDesktop.matches
  }

  setSubmenuEndHeight(submenu) {
    /* For mobile transition */
    const list = submenu.querySelector('[data-submenu-list]')
    const height = list.clientHeight

    submenu.style.height = `${height}px`
  }

  calculateMenuPosition(submenu, button) {
    const submenuList = submenu.querySelector('[data-submenu-list]')
    const buttonXPosition = button.getBoundingClientRect().left
    const submenuXPosition = submenuList.getBoundingClientRect().left
    const submenuWidth = submenuList.clientWidth
    const availableSpace = window.innerWidth - buttonXPosition

    if (submenuWidth > availableSpace) {
      return window.innerWidth - submenuXPosition - submenuWidth - 50
    }

    return buttonXPosition - submenuXPosition
  }

  positionOpenSubmenu(button, submenu) {
    const submenuList = submenu.querySelector('[data-submenu-list]')
    const newXPosition = this.calculateMenuPosition(submenu, button)

    if (minDesktop.matches) {
      submenuList.style.left = `${newXPosition}px`
      submenuList.style.position = 'relative'
    } else if (!minDesktop.matches) {
      submenuList.removeAttribute('style')
    }
  }

  openSubmenu(button, submenu) {
    const submenuLinks = [...submenu.querySelectorAll('a')]

    submenu.setAttribute('data-open', 'true')
    button.setAttribute('aria-expanded', 'true')
    submenu.removeAttribute('aria-hidden')
    submenu.hidden = false
    this.positionOpenSubmenu(button, submenu)

    setTimeout(() => {
      this.setSubmenuEndHeight(submenu)
      submenu.style.opacity = 1
      submenu.classList.add('is-open')
      submenuLinks.forEach((el) => {
        el.setAttribute('tabindex', '0')
      })
    }, 100)
  }

  closeSubmenu(button, submenu) {
    const submenuLinks = [...submenu.querySelectorAll('a')]
    const submenuList = submenu.querySelector('[data-submenu-list]')

    submenu.style.opacity = 0

    if (minDesktop.matches) {
      submenu.style.height = 'auto'
    } else {
      submenu.style.height = '0px'
    }

    submenu.classList.remove('is-open')

    setTimeout(() => {
      submenu.setAttribute('data-open', 'false')
      button.setAttribute('aria-expanded', 'false')
      submenu.setAttribute('aria-hidden', 'true')
      submenuList.style.left = 'auto'
      submenu.hidden = true

      submenuLinks.forEach((el) => {
        el.setAttribute('tabindex', '-1')
      })
    }, 300)
  }

  toggleSubmenu(button, submenu) {
    const isOpen = submenu.dataset.open === 'true'

    if (isOpen) {
      this.closeSubmenu(button, submenu)
    } else {
      this.openSubmenu(button, submenu)
    }
  }

  shouldCloseSubmenu(submenu, submenuIndex) {
    return (
      submenu.dataset.submenu != submenuIndex && submenu.dataset.open == 'true'
    )
  }

  closeOthers(submenuIndex) {
    const submenusToClose = this.submenus.filter((el) => {
      return this.shouldCloseSubmenu(el, submenuIndex)
    })

    submenusToClose.forEach((el) => {
      const button = this.getButtonFromDataAttribute(el)
      this.closeSubmenu(button, el)
    })
  }

  onMenuBtnClick(e) {
    if (!e.target.dataset.submenuButton) return

    const submenuIndex = e.target.dataset.submenuButton
    const submenu = this.getSubmenuFromDataAttribute(e.target)

    this.closeOthers(submenuIndex)
    this.toggleSubmenu(e.target, submenu)
  }

  getButtonFromDataAttribute(submenu) {
    const btnIndex = submenu.dataset.submenu

    return this.buttons.find((el) => {
      return el.dataset.submenuButton === btnIndex
    })
  }

  getSubmenuFromDataAttribute(button) {
    const submenuIndex = button.dataset.submenuButton

    return this.submenus.find((el) => {
      return el.dataset.submenu === submenuIndex
    })
  }

  closeOpenSubmenus() {
    this.buttons.forEach((button) => {
      const submenu = this.getSubmenuFromDataAttribute(button)
      this.closeSubmenu(button, submenu)
    })
  }

  onMenuResize() {
    this.closeOpenSubmenus()

    if (minDesktop.matches && !this.isDesktop) {
      this.submenus.forEach((submenu) => {
        submenu.style.height = 'auto'
      })
      this.menuContent.hidden = false
      this.isHidden = false
      this.isDesktop = true
    } else if (!minDesktop.matches && this.isDesktop) {
      this.closeOpenSubmenus()
      this.menuContent.hidden = true
      this.isHidden = true
      this.isDesktop = false
    }
  }

  initSubmenus() {
    this.buttons.forEach((button) => {
      const submenu = this.getSubmenuFromDataAttribute(button)
      this.closeSubmenu(button, submenu)
    })

    this.menuContent.addEventListener('click', this.onMenuBtnClick.bind(this))
    resize(this.onMenuResize.bind(this))
  }
}

export default MenuWithDropdowns
