import { minDesktop } from '../helpers/resize'
import Accordion from './accordion'
import Tabs from './tabs'

class AccordionTabs {
  constructor(el) {
    this.el = el
    this.accordion = new Accordion(el)
    this.tabs = new Tabs(el)

    minDesktop.addEventListener('change', this.onChange.bind(this))

    this.onChange()
  }

  onChange() {
    if (minDesktop.matches) {
      this.accordion.destroy()
      this.tabs.init()
    } else {
      this.tabs.destroy()
      this.accordion.init()
    }
  }
}

export const render = (el) => new AccordionTabs(el)
export default AccordionTabs
