const loadBundles = () => {
  /* Accordion */
  const accordions = [
    ...document.querySelectorAll('[data-behaviour="accordion"]'),
  ]

  accordions.forEach((accordion) => {
    import('../components/accordion.js').then((component) =>
      component.render(accordion)
    )
  })

  /* Parallax hero */
  const parallaxes = [
    ...document.querySelectorAll('[data-behaviour="parallax"]'),
  ]

  parallaxes.forEach((parallax) => {
    import('../components/parallax.js').then((component) =>
      component.render(parallax)
    )
  })

  /* Hero */
  const hero = document.querySelector('[data-behaviour="hero-video"]')

  if (hero) {
    import('../components/heroVideo.js').then((component) =>
      component.render(hero)
    )
  }

  /* Share buttons */
  if (document.querySelector('.share-btn')) {
    import('share-buttons').then(() => console.log('share'))
  }

  /* Filter */
  const filter = [...document.querySelectorAll('[data-behaviour="filter"]')]

  filter.forEach((filter) => {
    import('../components/filter.js').then((component) =>
      component.render(filter)
    )
  })

  /* Donate form */
  const donateForms = [
    ...document.querySelectorAll('[data-behaviour="donate-form"]'),
  ]

  donateForms.forEach((donateForm) => {
    import('../components/donateForm.js').then((component) =>
      component.render(donateForm)
    )
  })

  /* Pagination */
  const paginationComponents = [
    ...document.querySelectorAll('[data-behaviour="pagination"]'),
  ]

  paginationComponents.forEach((pagination) => {
    import('../components/pagination.js').then((component) =>
      component.render(pagination)
    )
  })

  /* Vue */
  if (document.querySelector('#app')) {
    import('../vue/app.js').then((vueApp) => {
      vueApp.default()
    })
  }

  if (document.querySelector('#star-tribute-app')) {
    import('../vue/star-tribute/app.js').then((vueAppStarTribute) => {
      vueAppStarTribute.default()
    })
  }
}

export default loadBundles
