import throttle from 'lodash.throttle'

const resize = (callback, wait = 600) => {
  window.addEventListener('resize', throttle(callback, wait))
}

const minDesktop = window.matchMedia('(min-width: 1024px)')
const minTablet = window.matchMedia('(min-width: 768px)')

export { minDesktop, minTablet }

export default resize
