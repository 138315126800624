import AccordionTabs from './components/accordionTabs'
import { initialize } from './helpers/init'
import loadBundles from './helpers/loadBundles'
import iframeResizer from './helpers/iframeResizer'

// import svg4everybody from 'svg4everybody'
import 'focus-visible'

// /* Import components as needed */
import MenuDropdown from './components/menu/menuDropdown'
import CookieBanner from './components/cookieBanner'
import Card from './components/card'
import InstagramShare from './components/instagramShare'
import Popup from './components/popup'

document.body.classList.remove('no-js')

document.fonts.onloadingdone = () => {
  document.body.classList.add('fonts-loaded')
}

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    document.documentElement.classList.remove('is-loading')
    document.documentElement.classList.add('is-loaded-complete')
  }
}

const initPageTransitions = () => {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    import('./helpers/pageTransitions').then((obj) => {
      const revealOnEnter = obj.default
      revealOnEnter()
    })
  } else {
    document.body.classList.add('no-observer')
  }
}

initPageTransitions()

// /* Initialize components as needed */
initialize('accordion-tabs', AccordionTabs)
initialize('menu', MenuDropdown)
initialize('cookie-banner', CookieBanner)
initialize('card', Card)
initialize('instagram-share', InstagramShare)
initialize('popup', Popup)

iframeResizer()

// /*
//   ** Conditionally load bundles **
//   ---
//   Only do this if you have large components or dependencies that you don't want to load on every page (e.g. a Vue application). Otherwise import and initialise them in the normal way, above. This example uses the accordion, but you probably don't want to do it this way for this component.
// */

loadBundles()
